




















import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class CtrlButton extends Vue {
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Emit("click")
  private buttonOnClickLeft(event: any) {}

  @Emit("click-right")
  private buttonOnClickRight(event: any) {}

  public requestFocus(): void {
    const button: HTMLInputElement = this.$refs.button as HTMLInputElement;
    button.focus();
  }
}
