

































import Vue from "vue";

import { Emit, Prop, Watch } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";
import TabsComponent from "@/components/parts/tab-component/TabsComponent.vue";
import ChatLogLineComponent from "@/components/chat/ChatLogLineComponent.vue";

@Component({
  components: { ChatLogLineComponent, TabsComponent }
})
export default class ChatLogViewer extends Vue {
  @Prop({ type: Array, required: true })
  private tabList!: any[];

  private get useTabList() {
    if (this.isViewTotalTab) return this.tabList;
    return this.tabList.filter((tab: any) => !tab.isTotal);
  }

  @Prop({ type: String, required: true })
  private activeChatTab!: string;

  @Prop({ type: String, required: true })
  private hoverChatTab!: string;

  @Prop({ type: Boolean, required: true })
  private isVertical!: boolean;

  @Prop({ type: Boolean, required: true })
  private isViewTime!: boolean;

  @Prop({ type: Boolean, required: true })
  private isViewTotalTab!: boolean;

  @Prop({ type: Number, required: true })
  private tabIndex!: number;

  @Prop({ type: Function, required: true })
  private textFunc!: Function;

  @Prop({ type: Array, required: true })
  private chatLogList!: any[];

  @Prop({ type: Object, required: true })
  private colorMap!: any;

  @Prop({ type: Boolean, required: true })
  private viewOption!: boolean;

  /**
   * チャットログ顯示タブ選擇されたとき的挙動
   * @param key タブ的key
   */
  @Emit("onSelect")
  private onSelect(key: string): void {}

  /**
   * チャットログ顯示分頁ホバーされたとき的挙動
   * @param key タブ的key
   */
  @Emit("onHover")
  private onHover(key: string): void {}

  /**
   * チャットタブ新增鍵クリックイベントハンドラ
   */
  @Emit("editTab")
  private editTab(): void {}

  @Watch("colorMap", { immediate: true, deep: true })
  private onChangeColorMap(colorMap: any) {
    for (const colorKey in colorMap) {
      if (!colorMap.hasOwnProperty(colorKey)) continue;
      document.documentElement.style.setProperty(
        `--${colorKey}`,
        colorMap[colorKey]
      );
    }
  }
}
