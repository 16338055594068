














































import { Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({ components: {} })
export default class TabsComponent extends Vue {
  @Getter("isModal") private isModal: any;

  @Prop({ type: Array, required: true })
  private tabList!: any[];

  @Prop({ type: String, required: true })
  private activeChatTab!: string;

  @Prop({ type: String, required: true })
  private hoverChatTab!: string;

  @Prop({ type: Boolean, required: true })
  private isVertical!: boolean;

  @Prop({ type: Number, required: true })
  private tabIndex!: number;

  @Prop({ type: Function, required: true })
  private textFunc!: Function;

  @Prop({ type: Boolean, required: true })
  private viewOption!: boolean;

  /**
   * 聊天視窗ログ顯示タブ選擇されたとき的挙動
   * @param key タブ的key
   */
  @Emit("onSelect")
  private chatTabOnSelect(key: string): void {}

  /**
   * 聊天視窗ログ顯示分頁ホバーされたとき的挙動
   * @param key タブ的key
   */
  @Emit("onHover")
  private chatTabOnHover(key: string): void {}

  /**
   * 聊天視窗タブ新增鍵クリックイベントハンドラ
   */
  @Emit("editTab")
  private tabAddButtonOnClick(): void {}

  private getTabClasses(tabObj: any, index: number) {
    return {
      active: tabObj.key === this.activeChatTab,
      hover: tabObj.key === this.hoverChatTab,
      unRead: tabObj.unRead > 0,
      vertical: this.isVertical,
      isLast: index === this.tabList.length - 1
    };
  }
}
